













import "reflect-metadata";
import { Vue, Component, Model } from "vue-property-decorator";
import { ChainAlias, WalletType } from "@/js/wallets/types";

@Component
export default class ChainSelect extends Vue {
  @Model("change", { type: String }) readonly chain!: ChainAlias;

  get isEVMSupported() {
    let wallet: WalletType | null = this.$store.state.activeWallet;
    if (!wallet) return false;
    return wallet.ethAddress;
  }

  setChain(val: ChainAlias) {
    this.$emit("change", val);
  }
}

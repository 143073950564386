<template>
  <div class="wallet_sidebar">
    <div class="stick">
      <div class="sc-780351c2-0 dlRsRe">
        <div class="sc-780351c2-1 hAEWgV">
          <img
            src="@/assets/djt-logo4.svg"
            alt="menu-logo"
            class="menu-logo icon"
          />
          <div class="sc-780351c2-3 iOTLmg">
            <div
              class="sc-780351c2-2 hVYBR"
              style="
                transform: translateY(48px) scaleY(40);
                transition: transform 0.15s ease 0s;
              "
            ></div>
            <span data-tooltip="ctrk4l5j" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet"
                :tooltip="'Home'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke="#868f97"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.0311 20.25H18.75C19.5784 20.25 20.25 19.5784 20.25 18.75V9.6943C20.25 9.25388 20.0564 8.83571 19.7207 8.55071L12.9689 2.81986C12.4096 2.34663 11.5904 2.34663 11.0311 2.81986L4.28109 8.53139C3.94426 8.8164 3.75 9.23525 3.75 9.67647V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H12V16.75"
                  ></path></svg></router-link></span
            ><span data-tooltip="ypimfeyn" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/transfer"
                :tooltip="'Send'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="#868f97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  class="feather feather-arrow-up-right"
                  viewBox="0 0 24 24"
                >
                  <line x1="7" x2="17" y1="17" y2="7" />
                  <polyline points="7 7 17 7 17 17" /></svg></router-link></span
            ><span data-tooltip="n8mkc4jx" class="sc-780351c2-4 bqnLPh"
              ><router-link
                :tooltip="'Intra-Chain'"
                to="/wallet/cross_chain"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#868f97"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M19 14H5l4.667 5M5 10h14l-4.667-5"
                  /></svg></router-link></span
            ><span data-tooltip="4j0tzaj6" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/earn"
                :tooltip="'Stake'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.75 3.75V5.75M16.25 3.75V5.75M7.75 9.75H16.25M5.25 5.75H18.75C19.5784 5.75 20.25 6.42157 20.25 7.25V18.75C20.25 19.5784 19.5784 20.25 18.75 20.25H5.25C4.42157 20.25 3.75 19.5784 3.75 18.75V7.25C3.75 6.42157 4.42157 5.75 5.25 5.75Z"
                    stroke="#868f97"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path></svg></router-link></span
            ><span data-tooltip="zn0sjsyv" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/studio"
                :tooltip="'Mint'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  stroke="#868f97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  class="feather feather-disc"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10" />
                  <circle cx="12" cy="12" r="3" /></svg></router-link
            ></span>
            <span data-tooltip="4j0tzaj6" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/activity"
                :tooltip="'Activity'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  stroke="#868f97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  class="feather feather-activity"
                  viewBox="0 0 24 24"
                >
                  <polyline
                    points="22 12 18 12 15 21 9 3 6 12 2 12"
                  /></svg></router-link
            ></span>
            <span data-tooltip="4j0tzaj6" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/keys"
                :tooltip="'Keys'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  stroke="#868f97"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  class="feather feather-key"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="m21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4"
                  /></svg></router-link
            ></span>
            <span data-tooltip="4j0tzaj6" class="sc-780351c2-4 bqnLPh"
              ><router-link
                to="/wallet/advanced"
                :tooltip="'Advanced'"
                class="sc-780351c2-5 fLeFZb"
                ><svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5208 20.6615C11.4384 21.1816 12.5617 21.1816 13.4794 20.6615L16.1326 19.1577L18.7615 17.6119C19.6708 17.0772 20.2324 16.1044 20.2408 15.0496L20.2651 12L20.2408 8.95034C20.2324 7.8956 19.6708 6.92275 18.7615 6.38812L16.1326 4.84229L13.4794 3.33846C12.5617 2.81835 11.4384 2.81835 10.5208 3.33846"
                    class="right"
                    stroke="#868f97"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M13.4793 20.6615C12.5617 21.1816 11.4383 21.1816 10.5207 20.6615L7.86749 19.1577L5.23853 17.6119C4.32929 17.0772 3.76761 16.1044 3.75923 15.0496L3.73499 12L3.75923 8.95034C3.76761 7.8956 4.32929 6.92275 5.23853 6.38812L7.86749 4.84229L10.5207 3.33846C11.4383 2.81835 12.5617 2.81835 13.4793 3.33846"
                    stroke="#868f97"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  ></path>
                  <circle
                    cx="12"
                    cy="12"
                    r="2.75"
                    stroke="#868f97"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  ></circle></svg></router-link
            ></span>
          </div>
          <div class="sc-780351c2-6 hEHaaX"></div>
        </div>
      </div>
      <div class="bottom">
        <AccountMenu class="wallet_link"></AccountMenu>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageSelect from "@/components/misc/LanguageSelect/LanguageSelect";
import Tooltip from "@/components/misc/Tooltip.vue";
import AccountMenu from "@/components/wallet/sidebar/AccountMenu";
export default {
  components: {
    AccountMenu,
    LanguageSelect,
  },
};
</script>
<style lang="scss" scoped>
@use "../../main";

[tooltip]:hover:before {
  border-radius: 4px !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 54px !important;
  transform: translateY(-6px) !important;
  content: attr(tooltip);
  position: absolute !important;
  background-color: #000000 !important;
  color: #ddd !important;
  width: 88px !important;
  min-width: 88px !important;
  z-index: 12 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 4px 4px !important;
}

img.menu-logo.icon {
  width: 20px;
  margin-top: 4px;
}

.iOTLmg {
  position: relative;
  width: 100%;
  text-align: center;
}
.bqnLPh:not(:last-child) {
  margin-bottom: 10px;
}
.dlRsRe {
  display: flex;
}

.bqnLPh {
  display: block;
}
a.sc-780351c2-5.fLeFZb {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  z-index: 10;
}

a.sc-780351c2-5.fLeFZb.router-link-exact-active.router-link-active::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  opacity: 1;
  background: #479ffa;
  transition: opacity 0.25s var(--ease-out);
  --ease-in: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-in-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.fLeFZb:hover {
  content: "";
  position: absolute;
  inset: 0px 0px 0px;
  margin: 0px auto;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background: #868f9714;
  transition: opacity 0.25s var(--ease-out);
  --ease-in: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-in-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.stick::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  background: radial-gradient(
    322px at 50% 50%,
    rgb(255 255 255 / 16%) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.sc-780351c2-1.hAEWgV::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  background: radial-gradient(
    322px at 50% 50%,
    rgb(255 255 255 / 24%) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.hAEWgV {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 51px;
  height: 92vh;
  top: 0px;
  padding: 25px 0px 56px;
  z-index: 10;
}

.wallet_sidebar {
  .alert_icon {
    color: #f00;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .brand {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      object-fit: contain;
    }
  }

  .links {
    padding: 0 !important;
    display: flex;
    flex-direction: column;

    a {
      opacity: 0.6;
      color: var(--primary-color-light);
      text-decoration: none;

      img {
        opacity: 0.5;
      }
    }

    .wallet_link {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    a.router-link-exact-active {
      color: var(--primary-color) !important;
      opacity: 1;
      background-color: var(--bg-wallet);

      img {
        opacity: 1;
      }
    }

    img {
      width: 20px;
      margin-right: 15px;
      object-fit: contain;
    }
  }
}
.wallet_export {
  span {
    display: block;
    margin-right: 15px;
    width: 20px;
  }
}
.bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;

  > * {
    display: flex;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
  }
}

@include main.medium-device {
  .brand {
    height: 90px;
  }
  .links {
    font-size: 12px;
  }
}
</style>

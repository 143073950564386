<template>
  <div class="top_cards">
    <balance-card class="top_card balance_card"></balance-card>
    <ChainCard></ChainCard>
    <address-card class="top_card addr_card"></address-card>
  </div>
</template>
<script>
import BalanceCard from "./TopCards/BalanceCard/BalanceCard";
import ChainCard from "./TopCards/ChainCard/ChainCard";
import AddressCard from "./TopCards/AddressCard/AddressCard";

export default {
  components: {
    BalanceCard,
    ChainCard,
    AddressCard,
  },
};
</script>
<style scoped lang="scss">
@use '../../main';

.top_cards {
  display: grid;
  grid-template-columns: 576px 1fr 1fr;
  grid-gap: 14px;
}
.top_card {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: var(--primary-color);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: var(--bg-wallet-light);
  overflow: hidden;
  border-radius: 5px;
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.balance_card {
}

.addr_card {
}
.card_left {
  background-color: #f4efff;
  flex-basis: 70px;
  flex-shrink: 0;
  padding: 15px;
  border-bottom-right-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    fill: #ddd;
  }
}

.card_right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 18px;
  padding-bottom: 8px;
}

.top_card h4 {
  color: main.$primary-color;
  font-weight: bold;
  text-align: left;
}
.top_card p {
  word-break: break-all;
  text-align: left;
  flex-grow: 1;
  margin: 0;
  font-size: 14px;
  font-family: Inconsolata, monospace;
}

.balance {
  font-size: 24px !important;
  font-family: Rubik !important;
}

.top_card .buts {
  width: 100%;
  text-align: right;
}
.top_card .buts button {
  font-size: 18px;
  margin: 0px 18px;
  margin-right: 0px;
  position: relative;
  outline: none;
}

.buts img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

@media only screen and (max-width: main.$mobile_width) {
}

@include main.medium-device {
  .top_cards {
    grid-template-columns: 364px 1fr 1fr;
    grid-gap: 8px;
  }
}

@include main.mobile-device {
  .top_cards {
    grid-template-columns: none;
    grid-template-rows: min-content;
    grid-gap: 12px;
    padding: 0px 0px;
  }

  .top_card {
    padding: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .balance_card {
    grid-column: unset;
  }

  .addr_card {
    grid-column: unset;
  }
}

//@media only screen and (max-width: main.$width_m) {
//
//}
</style>

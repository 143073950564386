
































































import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import CopyText from "@/components/misc/CopyText.vue";
import QRModal from "@/components/modals/QRModal.vue";
import PaperWallet from "@/components/modals/PaperWallet/PaperWallet.vue";
import Guider from "@/components/misc/Guider.vue";
import QRCode from "qrcode";
import { KeyPair as AVMKeyPair } from "avalanche/dist/apis/avm";
import { WalletType, WalletNameType } from "@/js/wallets/types";
import Tooltip from "@/components/misc/Tooltip.vue";

import MnemonicWallet, {
  AVA_ACCOUNT_PATH,
  LEDGER_ETH_ACCOUNT_PATH,
} from "@/js/wallets/MnemonicWallet";
import { LedgerWallet } from "@/js/wallets/LedgerWallet";

import ChainSelect from "@/components/wallet/TopCards/AddressCard/ChainSelect.vue";
import { ChainIdType } from "@/constants";
import { ava } from "@/AVA";
import { getPreferredHRP } from "avalanche/dist/utils";
@Component({
  components: {
    CopyText,
    Guider,
    PaperWallet,
    QRModal,
    ChainSelect,
    Tooltip,
  },
})
export default class AddressCard extends Vue {
  colorLight: string = "#FFF";
  colorDark: string = "#242729";
  chainNow: ChainIdType = "V";

  $refs!: {
    qr_modal: QRModal;
    print_modal: PaperWallet;
    qr: HTMLCanvasElement;
  };

  @Watch("activeAddress")
  onaddrchange() {
    this.updateQR();
  }

  @Watch("$root.theme", { immediate: true })
  onthemechange(val: string) {
    if (val === "night") {
      this.colorDark = "#E5E5E5";
      this.colorLight = "#242729";
    } else {
      this.colorDark = "#242729";
      this.colorLight = "#FFF";
    }
    this.updateQR();
  }

  get addressLabel(): string {
    switch (this.chainNow) {
      default:
        return this.$t("top.address.title_x") as string;
      case "M":
        return this.$t("top.address.title_p") as string;
      case "U":
        return this.$t("top.address.title_c") as string;
    }
  }

  get tooli() {
    switch (this.chainNow) {
      default:
        return require(`@/assets/guido.svg`);
      case "M":
        return require(`@/assets/guido.svg`);
      case "U":
        return require(`@/assets/guido.svg`);
    }
  }

  get addressMsg(): string {
    switch (this.chainNow) {
      default:
        return this.getAddressMsgX();
      case "M":
        return this.$t("top.address.desc_p") as string;
      case "U":
        return this.$t("top.address.desc_c") as string;
    }
  }

  get addressMsgz(): string {
    switch (this.chainNow) {
      default:
        return this.getAddressMsgXz();
      case "M":
        return this.$t("top.address.desc_pz") as string;
      case "U":
        return this.$t("top.address.desc_cz") as string;
    }
  }

  getAddressMsgX() {
    if (this.activeWallet?.type === "singleton") {
      return this.$t("top.address.desc_x_1") as string;
    } else {
      return `${this.$t("top.address.desc_x_1")}` as string;
    }
  }

  getAddressMsgXz() {
    if (this.activeWallet?.type === "singleton") {
      return this.$t("top.address.desc_x_1z") as string;
    } else {
      return `${this.$t("top.address.desc_x_1z")}` as string;
    }
  }

  get isDayTheme(): boolean {
    //@ts-ignore
    return this.$root.theme === "day";
  }

  get walletType(): WalletNameType {
    let wallet = this.activeWallet;
    if (!wallet) return "mnemonic";
    return wallet.type;
  }

  get activeWallet(): WalletType | null {
    return this.$store.state.activeWallet;
  }
  get address() {
    let wallet = this.activeWallet;
    if (!wallet) {
      return "-";
    }
    return wallet.getCurrentAddressAvm();
  }

  get addressPVM() {
    let wallet = this.activeWallet;
    if (!wallet) {
      return "-";
    }

    return wallet.getCurrentAddressPlatform();
  }

  get addressEVM() {
    let wallet = this.activeWallet;
    if (!wallet) {
      return "-";
    }

    return "0x" + wallet.getEvmAddress();
  }

  get activeAddress(): string {
    switch (this.chainNow) {
      case "V":
        return this.address;
      case "M":
        return this.addressPVM;
      case "U":
        return this.addressEVM;
    }
    return this.address;
  }

  get activeIdx(): number {
    const wallet = this.activeWallet as MnemonicWallet;
    const walletType = wallet.type;

    if (walletType === "singleton") return 0;

    switch (this.chainNow) {
      case "V":
        return wallet.getExternalActiveIndex();
      case "M":
        return wallet.getPlatformActiveIndex();
      default:
        return 0;
    }
  }

  viewQRModal() {
    // @ts-ignore
    this.$refs.qr_modal.open();
  }
  viewPrintModal() {
    let modal = this.$refs.print_modal;
    // @ts-ignore
    modal.open();
  }
  updateQR() {
    let canvas = this.$refs.qr as HTMLCanvasElement;
    if (!canvas) return;

    let size = canvas.clientWidth;
    QRCode.toCanvas(
      canvas,
      this.activeAddress,
      {
        scale: 6,
        color: {
          light: this.colorLight,
          dark: this.colorDark,
        },
        width: size,
        // height: size,
      },
      function (error: any) {
        if (error) console.error(error);
      }
    );
  }

  async verifyLedgerAddress() {
    const wallet = this.activeWallet as LedgerWallet;

    let networkId = ava.getNetworkID();
    let hrp = getPreferredHRP(networkId);

    switch (this.chainNow) {
      case "V":
      case "M":
        wallet.app.getWalletAddress(
          `${AVA_ACCOUNT_PATH}/0/${this.activeIdx}`,
          hrp
        );
        break;
      case "U":
        wallet.ethApp.getAddress(`${LEDGER_ETH_ACCOUNT_PATH}`);
    }
  }

  mounted() {
    this.updateQR();
  }
}
